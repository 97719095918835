const AlertPopup = ({ onYesHandler, btnYesText, message, isOpen }) => {
  const msg = message.replace('\n', '<br/>');
  return (
    <div className={`share-pop-ui ${isOpen ? 'open' : ''}`}>
      <span className="dim"></span>
      <div className="dp-table pos-a">
        <div className="dp-cell">
          <div className="share-alert-box">
            <div className="share-txt-area">
              <div className="share-txt">
                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
              </div>
            </div>
            <div className="share-btn-area">
              <button className="button-ok clickable" onClick={onYesHandler}>
                {btnYesText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
