import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as homeLogApi from '../api/homeLog';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

//ACTION TYPE
const [GET_HLOG_LIST, GET_HLOG_LIST_SUCCESS, GET_HLOG_LIST_FAILURE] = createRequestActionTypes('homeLog/GET_HLOG__LIST');
const [GET_HLOG_DETAIL_LIST, GET_HLOG_DETAIL_LIST_SUCCESS, GET_HLOG_DETAIL_LIST_FAILURE] =
  createRequestActionTypes('homeLog/GET_HLOG_DETAIL_LIST');
const [GET_HLOG_THUMBNAIL_LIST, GET_HLOG_THUMBNAIL_LIST_SUCCESS, GET_HLOG_THUMBNAIL_LIST_FAILURE] =
  createRequestActionTypes('homeLog/GET_HLOG_THUMBNAIL_LIST');
const [GET_HLOG_CNT, GET_HLOG_CNT_SUCCESS, GET_HLOG_CNT_FAILURE] = createRequestActionTypes('homeLog/GET_HLOG_CNT');
const CLEAR_HLOG_LIST = 'homeLog/CLEAR_HLOG_LIST';
const UPDATE_HLOG_DETAIL_LIST = 'homeLog/UPDATE_HLOG_DETAIL_LIST';
const UPDATE_HLOG_THUMBNAIL_LIST = 'homeLog/UPDATE_HLOG_THUMBNAIL_LIST';

//마지막 스크롤 , 슬라이드 값
const SAVE_SCROLL_POS = 'homeLog/SAVE_SCROLL_POS';
const SAVE_SLIDE_POS = 'homeLog/SAVE_SLIDE_POS';

//CREATE ACTION
export const getHLogList = createAction(GET_HLOG_LIST, ({ p, yyyy, page, pageSize }) => ({ p, yyyy, page, pageSize }));
export const getHLogDetailList = createAction(GET_HLOG_DETAIL_LIST, ({ p, yyyy, page, pageSize }) => ({
  p,
  yyyy,
  page,
  pageSize,
}));
export const getHLogThnList = createAction(GET_HLOG_THUMBNAIL_LIST, ({ p, yyyy, page, pageSize }) => ({
  p,
  yyyy,
  page,
  pageSize,
}));
export const getHLogCntList = createAction(GET_HLOG_CNT, (p) => p);

export const updateHLogDetailList = createAction(UPDATE_HLOG_DETAIL_LIST, (hLogDetailList) => hLogDetailList);
export const updateHLogThnList = createAction(UPDATE_HLOG_THUMBNAIL_LIST, (hLogThnList) => hLogThnList);
export const clearHLogList = createAction(CLEAR_HLOG_LIST);
export const saveScrollPos = createAction(SAVE_SCROLL_POS, (pos) => pos);
export const saveSlidePos = createAction(SAVE_SLIDE_POS, (pos) => pos);

//CREATE ACTION SAGA
const getHLogListSaga = createRequestSaga(GET_HLOG_LIST, homeLogApi.getHlogList, true);
const getHLogDetailListSaga = createRequestSaga(GET_HLOG_DETAIL_LIST, homeLogApi.getHlogList, true);
const getHLogThnListSaga = createRequestSaga(GET_HLOG_THUMBNAIL_LIST, homeLogApi.getHlogList, true);
const getHLogCntListSaga = createRequestSaga(GET_HLOG_CNT, homeLogApi.getHlogCnt);

//BOOKPICK SAGA
export function* homeLogSaga() {
  yield takeLatest(GET_HLOG_LIST, getHLogListSaga);
  yield takeLatest(GET_HLOG_DETAIL_LIST, getHLogDetailListSaga);
  yield takeLatest(GET_HLOG_THUMBNAIL_LIST, getHLogThnListSaga);
  yield takeLatest(GET_HLOG_CNT, getHLogCntListSaga);
}

//STATE
const initailState = {
  hLogDetailInfo: null,
  hLogDetailList: null,
  hLogThnInfo: null,
  hLogThnList: null,
  scrollPos: 0,
  slidePos: 0,
  hLogCountList: null,
};

const createHlogThnList = (page = 1) => {
  let list = [];
  for (let i = 0; i < 8; i++) {
    list[i] = {
      cd: (page - 1) * 8 + i, //22990001,
      grp: '학습분석 상',
      grpCd: 'A',
      name: '평가상 - 국어부문',
      property: `홈런 챌린지 - 영어책 모두 읽기${(page - 1) * 8 + i}`,
      templateUrl: 'https://dtpk5hqp6n86z.cloudfront.net/image/tt_가을.png',
      regDttm: '2022-10-07 10:30:00',
    };
  }
  return list;
};

const createHlogDetailList = (page = 1) => {
  let list = [];
  for (let i = 0; i < 8; i++) {
    list[i] = {
      cd: (page - 1) * 8 + i,
      id: `ISE-FFC-10-000${(page - 1) * 8 + i}`,
      grp: '선생님 상',
      grpCd: 'M',
      name: `성실 만렙 상${(page - 1) * 8 + i}`,
      studName: '뚜루뚜루',
      property: '홈런 챌린지 - 영어책 모두 읽기',
      period: '2022년 10월 01일 ~ 2022년 10월 11일',
      cont: '위 학생은.... 칭찬합니다.',
      tchrName: '아이뚜루',
      templateUrl: 'https://dtpk5hqp6n86z.cloudfront.net/image/tt_가을.png',
      regDttm: '2022-10-06 10:30:00',
    };
  }
  return list;
};
//REDUCER
const homeLog = handleActions(
  {
    [GET_HLOG_LIST_SUCCESS]: (state, { payload: hlogList }) => ({
      ...state,
      hLogDetailInfo: {
        totalCnt: hlogList.totalCnt,
        pageCnt: hlogList.pageCnt,
        currPage: hlogList.currPage,
      },
      hLogThnInfo: {
        totalCnt: hlogList.totalCnt,
        pageCnt: hlogList.pageCnt,
        currPage: hlogList.currPage,
      },
      hLogThnList: hlogList.hlogList,
      hLogDetailList: hlogList.hlogList,
    }),
    [GET_HLOG_LIST_FAILURE]: (state, { payload: hlogList }) => ({
      ...state,
    }),
    [GET_HLOG_DETAIL_LIST_SUCCESS]: (state, { payload: hLogDetailList }) => {
      return {
        ...state,
        hLogDetailInfo: {
          totalCnt: hLogDetailList.totalCnt,
          pageCnt: hLogDetailList.pageCnt,
          currPage: hLogDetailList.currPage,
        },
        hLogDetailList: [...state.hLogDetailList, ...hLogDetailList.hlogList],
      };
    },
    [GET_HLOG_DETAIL_LIST_FAILURE]: (state, { payload: data }) => {
      return {
        ...state,
      };
    },
    [GET_HLOG_THUMBNAIL_LIST_SUCCESS]: (state, { payload: hLogThnList }) => {
      return {
        ...state,
        hLogThnInfo: {
          totalCnt: hLogThnList.totalCnt,
          pageCnt: hLogThnList.pageCnt,
          currPage: hLogThnList.currPage,
        },
        hLogThnList: [...state.hLogThnList, ...hLogThnList.hlogList],
      };
    },
    [GET_HLOG_THUMBNAIL_LIST_FAILURE]: (state, { payload: data }) => {
      return {
        ...state,
      };
    },
    [UPDATE_HLOG_DETAIL_LIST]: (state, { payload: data }) => ({
      ...state,
      hLogDetailInfo: {
        ...state.hLogDetailInfo,
        currPage: data.page,
      },
      hLogDetailList: [...state.hLogDetailList, ...data.hlogList],
    }),
    [UPDATE_HLOG_THUMBNAIL_LIST]: (state, { payload: data }) => ({
      ...state,
      hLogThnInfo: {
        ...state.hLogThnInfo,
        currPage: data.page,
      },
      hLogThnList: [...state.hLogThnList, ...data.hlogList],
    }),
    [SAVE_SCROLL_POS]: (state, { payload: scrollPos }) => ({
      ...state,
      scrollPos,
    }),
    [SAVE_SLIDE_POS]: (state, { payload: slidePos }) => ({
      ...state,
      slidePos,
    }),
    [CLEAR_HLOG_LIST]: (state) => ({
      ...state,
      hLogThnList: null,
      hLogDetailList: null,
    }),
    [GET_HLOG_CNT_SUCCESS]: (state, { payload: { hLogCountList } }) => {
      const defaultHlogCount = {
        yyyy: new Date().getFullYear(),
        hLogCnt: 0,
      };
      const maxHlogCount = hLogCountList[hLogCountList.length - 1] || defaultHlogCount; //년도 기준으로 오름차순
      const minHlogCount = hLogCountList[0] || defaultHlogCount;
      const hLogCountLen = maxHlogCount.yyyy - minHlogCount.yyyy + 1;
      if (hLogCountLen !== hLogCountList?.length) {
        console.log('hLogCount Date edit');
        let newhLogCountList = [];
        for (let i = 0; i < hLogCountLen; i++) {
          const hLogCount = hLogCountList.find((el) => el.yyyy === minHlogCount.yyyy + i);
          if (hLogCount) {
            newhLogCountList.push(hLogCount);
          } else {
            newhLogCountList.push({
              yyyy: minHlogCount.yyyy + i,
              hLogCnt: 0,
            });
          }
        }
        console.log(newhLogCountList);
        return {
          ...state,
          hLogCountList: newhLogCountList,
        };
      }
      return {
        ...state,
        hLogCountList,
      };
    },
    [GET_HLOG_CNT_FAILURE]: (state) => ({
      ...state,
      hLogCountList: null,
    }),
  },
  initailState,
);

export default homeLog;
