import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { getEnglishBookChallenge, getEnglishBookChMissonList, getEnglishBookChReward } from '../api/englishBookPick';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { put } from 'redux-saga/effects';
//ACTION TYPE
const [ENGLISH_BOOK_CHALLENGE, ENGLISH_BOOK_CHALLENGE_SUCCESS, ENGLISH_BOOK_CHALLENGE_FAILURE] =
  createRequestActionTypes('bookpick/ENGLISH_BOOK_CHALLENGE');
const [ENGLISH_BOOK_CH_REWARD, ENGLISH_BOOK_CH_REWARD_SUCCESS, ENGLISH_BOOK_CH_REWARD_FAILURE] =
  createRequestActionTypes('bookpick/ENGLISH_BOOK_CH_REWARD');
const [ENGLISH_BOOK_CH_MISSION_LIST, ENGLISH_BOOK_CH_MISSION_LIST_SUCCESS, ENGLISH_BOOK_CH_MISSION_LIST_FAILURE] = createRequestActionTypes(
  'bookpick/ENGLISH_BOOK_CH_MISSION_LIST',
);

const ENGLISH_BOOK_CHALLENGE_UPDATE = 'bookpick/ENGLISH_BOOK_CHALLENGE_UPDATE';
const SET_ENGLISH_BOOK_CHALLENGE = 'bookpick/SET_ENGLISH_BOOK_CHALLENGE';
const CLEAR_ENGLISH_BOOK_CHALLENGE = 'bookpick/CLEAR_ENGLISH_BOOK_CHALLENGE';
const CLEAR_ENGLISH_BOOK_CH_MISSION_LIST = 'bookpick/CLEAR_ENGLISH_BOOK_CH_MISSION_LIST';
const CLEAR_ENGLISH_BOOK_CH_REWARD = 'bookpick/CLEAR_ENGLISH_BOOK_BOOK_CH_REWARD';
const CLEAR_ALL_ENGLISH_BOOK_CHALLENGE = 'bookpick/CLEAR_ALL_ENGLISH_BOOK_CHALLENGE';

// const ENGLISH_BOOK_CHALLENGE_UPDATE = 'bookpick/ENGLISH_BOOK_CHALLENGE_UPDATE';
// const CLEAR_ENGLISH_BOOK_CHALLENGE_UPDATE = 'bookpick/CLEAR_ENGLISH_BOOK_CHALLENGE_UPDATE';
// const CLEAR_ENGLISH_BOOK_CHALLENGE = 'bookpick/CLEAR_ENGLISH_BOOK_CHALLENGE';
// const LOCAL_ENGLISH_BOOK_CHALLENGE_UPDATE = 'bookpick/LOCAL_ENGLISH_BOOK_CHALLENGE_UPDATE';
// const LOCAL_ENGLISH_BOOK_CH_REWARD = 'bookpick/LOCAL_ENGLISH_BOOK_CH_REWARD';
// const LOCAL_ENGLISH_BOOK_CH_MISSION_LIST = 'bookpick/LOCAL_ENGLISH_BOOK_CH_MISSION_LIST';
// const READ_BOOKPICK = 'bookpick/READ_BOOKPICK';
// const UPDATE_CHALLENGE_STATE = 'bookpick/UPDATE_CHALLENGE_STATE';
// const LOCAL_CHALLENGE_STEP_STATUS_UPDATE = 'bookpick/LOCAL_CHALLENGE_STEP_STATUS_UPDATE';
// const HISTORY_BOOKLIST = 'bookpick/HISTORY_BOOKLIST';

//CREATE ACTION
export const englishBookChallenge = createAction(ENGLISH_BOOK_CHALLENGE, (p) => p);
export const englishBookChReward = createAction(ENGLISH_BOOK_CH_REWARD, ({ p, misStep }) => ({ p, misStep }));
export const englishBookChMissionList = createAction(ENGLISH_BOOK_CH_MISSION_LIST, ({ p, misStep }) => ({ p, misStep }));
export const setEnglishBookChallenge = createAction(SET_ENGLISH_BOOK_CHALLENGE, ({ p, chCd, misStep, misStatusCd }) => ({
  p,
  chCd,
  misStep,
  misStatusCd,
}));

export const englishBookChallengeUpdate = createAction(ENGLISH_BOOK_CHALLENGE_UPDATE, (params) => params);
export const clearEnglishBookChallenge = createAction(CLEAR_ENGLISH_BOOK_CHALLENGE);
export const clearEnglishBookChMissionList = createAction(CLEAR_ENGLISH_BOOK_CH_MISSION_LIST);
export const clearEnglishBookChReward = createAction(CLEAR_ENGLISH_BOOK_CH_REWARD);
export const clearAllEnglishBookChallenge = createAction(CLEAR_ALL_ENGLISH_BOOK_CHALLENGE);

// export const localEnglishBookChallenge = createAction(LOCAL_ENGLISH_BOOK_CHALLENGE_UPDATE);
// export const localEnglishBookChReward = createAction(LOCAL_ENGLISH_BOOK_CH_REWARD);
// export const localEnglishBookChMissionList = createAction(LOCAL_ENGLISH_BOOK_CH_MISSION_LIST);
// export const readBookPick = createAction(READ_BOOKPICK);
// export const updateChallengeState = createAction(UPDATE_CHALLENGE_STATE);
// export const localChallengeStepStatusUpdate = createAction(LOCAL_CHALLENGE_STEP_STATUS_UPDATE, ({ misStep, stepStausCd }) => ({
//   misStep,
//   stepStausCd,
// }));
// export const historyBookList = createAction(HISTORY_BOOKLIST, ({ misStep, maxStep }) => ({ misStep, maxStep }));

// export const ENGLISHBookChallengeUpdate = createAction(ENGLISH_BOOK_CHALLENGE_UPDATE, (bookChallenge) => (bookChallenge));
// export const clearENGLISHBookChallengeUpdate = createAction(CLEAR_ENGLISH_BOOK_CHALLENGE_UPDATE);
// export const clearENGLISHBookChallenge = createAction(CLEAR_ENGLISH_BOOK_CHALLENGE)
//CREATE ACTION SAGA
const englishBookChallengeSaga = createRequestSaga(ENGLISH_BOOK_CHALLENGE, getEnglishBookChallenge);
const englishBookChRewardSaga = createRequestSaga(ENGLISH_BOOK_CH_REWARD, getEnglishBookChReward);
const englishBookChMissionListSaga = createRequestSaga(ENGLISH_BOOK_CH_MISSION_LIST, getEnglishBookChMissonList);
// const englishBookChallengeSaga = localReqeustSaga(ENGLISH_BOOK_CHALLENGE, getEnglishBookChallenge);
// const englishBookChRewardSaga = localReqeustSaga(ENGLISH_BOOK_CH_REWARD, getEnglishBookChReward);
// const englishBookChMissionListSaga = localReqeustSaga(ENGLISH_BOOK_CH_MISSION_LIST, getEnglishBookChMissonList);

//BOOKPICK SAGA
export function* englishBookPickSaga() {
  yield takeLatest(ENGLISH_BOOK_CHALLENGE, englishBookChallengeSaga);
  yield takeLatest(ENGLISH_BOOK_CH_REWARD, englishBookChRewardSaga);
  yield takeLatest(ENGLISH_BOOK_CH_MISSION_LIST, englishBookChMissionListSaga);
}
// export const createRequestActionTypes = (type) => {
//   const SUCCESS = `${type}_SUCCESS`;
//   const FAILURE = `${type}_FAILURE`;
//   return [type, SUCCESS, FAILURE];
// };

function localReqeustSaga(type, reuqest) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return function* (action) {
    try {
      console.log(type);
      yield put({
        type: SUCCESS,
        payload: '',
      });
    } catch (e) {}
  };
}

const initEnglishBookChallenge = () => {
  return {
    misStep: 1,
    stepStatusCd: -1,
    studLevel: 1,
  };
};

const initEnglishBookChReward = (step = 1) => {
  const reward = ['가로등', '벤치', '분수대', '열기구', '아이스크림카트', '관람차', '회전목마', '눈사람', '썰매', '시계탑'];
  return {
    rewardStep: 1,
    rewardNm: reward[step - 1],
    rewardDt: null,
    rewardTodayYn: 0,
    rewardMotionInfo: {
      motionNo: 13,
      mtImg1Url: 'type01',
      mtImg2Url: 'https://xcdn.home-learn.com/homelearn/f_project/metaphor/lottie/animal/1.json',
      mtSoundUrl: '',
      mtSpchBbImgUrl: 'mg-bubble-en',
      mtSpchBbContent: '영어 독서픽 1단계 완료',
      mtAdditions: 'img-bookpick-bg02',
    },
  };
};

const initEnglishBookChMissionList = (step = 1) => {
  const totalCount = step <= 9 ? step + 1 : step + 2;
  return {
    stepStatusCd: -1,
    studLevel: 1,
    taskCnt: totalCount,
    compCnt: 0,
    bookList: [],
  };
};

const initHistoryEnglishBookChMissionList = (step = 1) => {
  const totalCount = step <= 9 ? step + 1 : step + 2;
  return {
    stepStatusCd: 2,
    studLevel: 1,
    taskCnt: totalCount,
    compCnt: totalCount,
    bookList: createBookList(totalCount),
  };
};

const createBookList = (count = 1) => {
  let list = [];
  for (let i = 0; i < count; i++) {
    list.push({
      misOrderNo: i + 1,
      bookId: 98 + i,
      bookTitle: `Granny's Recipe${i + 1}`,
      bookCoverUrl:
        'https://api.v2.bookrclass.com/api/media/Ym9vay1jb3Zlci10YWxsL0Yvdi9GdlZsd2tRS3VKWFJIQVk0T3ZTeXp1YjZ5dGpnNUxoQmo1dnB2YzdUd1guanBn/original_256.jpg',
    });
  }
  return list;
};

//STATE
const initailState = {
  bookChallenge: null,
  bookChallengeError: null,
  bookChReward: null,
  bookChMissionList: null,
  bookChallengeUpdated: null,

  //local Data
  localEnglishBookChallenge: {
    misStep: 1,
    stepStatusCd: -1,
    studLevel: 1,
  },
  localEnglishBookChReward: {
    rewardStep: 1,
    rewardNm: '가로등',
    rewardDt: null,
    rewardTodayYn: 0,
    rewardMotionInfo: {
      motionNo: 13,
      mtImg1Url: 'type01',
      mtImg2Url: 'https://xcdn.home-learn.com/homelearn/f_project/metaphor/lottie/animal/1.json',
      mtSoundUrl: '',
      mtSpchBbImgUrl: 'mg-bubble-en',
      mtSpchBbContent: '영어 독서픽 1단계 완료',
      mtAdditions: 'img-bookpick-bg02',
    },
  },
  localnglishBookChMissionList: {
    stepStatusCd: -1,
    studLevel: 1,
    taskCnt: 2,
    compCnt: 0,
    bookList: [],
  },
};

//REDUCER
const englishBookPick = handleActions(
  {
    [ENGLISH_BOOK_CHALLENGE_SUCCESS]: (state, { payload: englishBookChallenge }) => {
      return {
        ...state,
        bookChallenge: englishBookChallenge,
        bookChallengeError: null,
      };
    },
    [ENGLISH_BOOK_CHALLENGE_FAILURE]: (state) => ({
      ...state,
      bookChallenge: null,
      bookChallengeError: true,
    }),
    [ENGLISH_BOOK_CH_REWARD_SUCCESS]: (state, { payload: englishBookChReward }) => ({
      ...state,
      bookChReward: englishBookChReward,
    }),
    [ENGLISH_BOOK_CH_REWARD_FAILURE]: (state) => ({
      ...state,
      bookChReward: null,
    }),
    [ENGLISH_BOOK_CH_MISSION_LIST_SUCCESS]: (state, { payload: englishBookChMissionList }) => ({
      ...state,
      bookChMissionList: englishBookChMissionList,
    }),
    [ENGLISH_BOOK_CH_MISSION_LIST_FAILURE]: (state) => ({
      ...state,
      bookChMissionList: null,
    }),
    [ENGLISH_BOOK_CHALLENGE_UPDATE]: (state, { payload: bookChallenge }) => {
      console.log(bookChallenge);
      return { ...state, bookChallenge };
    },
    [CLEAR_ENGLISH_BOOK_CHALLENGE]: (state) => ({
      ...state,
      bookChallenge: null,
    }),
    [CLEAR_ENGLISH_BOOK_CH_MISSION_LIST]: (state) => ({
      ...state,
      bookChMissionList: null,
    }),
    [CLEAR_ENGLISH_BOOK_CH_REWARD]: (state) => ({
      ...state,
      bookChReward: null,
    }),
    [CLEAR_ALL_ENGLISH_BOOK_CHALLENGE]: (state) => ({
      ...state,
      bookChallenge: null,
      bookChMissionList: null,
      bookChReward: null,
    }),
    // [LOCAL_ENGLISH_BOOK_CHALLENGE_UPDATE]: (state) => {},
    // [LOCAL_ENGLISH_BOOK_CH_REWARD]: (state) => {},
    // [LOCAL_ENGLISH_BOOK_CH_MISSION_LIST]: (state) => {},
    // [READ_BOOKPICK]: (state) => {
    //   console.log('READ_BOOKPICK');
    //   let { localEnglishBookChallenge, localnglishBookChMissionList } = state;
    //   if (localnglishBookChMissionList.compCnt < localnglishBookChMissionList.taskCnt) {
    //     localnglishBookChMissionList.compCnt++;
    //     localnglishBookChMissionList.bookList = createBookList(localnglishBookChMissionList.compCnt);
    //     if (localnglishBookChMissionList.compCnt === localnglishBookChMissionList.taskCnt) {
    //       localnglishBookChMissionList.stepStatusCd = 2;
    //       localEnglishBookChallenge.stepStatusCd = 2;
    //     }
    //   }
    //   return {
    //     ...state,
    //     localnglishBookChMissionList,
    //     localEnglishBookChallenge,
    //   };
    // },
    // [UPDATE_CHALLENGE_STATE]: (state) => {
    //   console.log('UPDATE_CHALLENGE_STATE');
    //   let { localEnglishBookChallenge, localEnglishBookChReward, localnglishBookChMissionList } = state;
    //   if (localEnglishBookChallenge.stepStatusCd === -1) {
    //     localEnglishBookChallenge.stepStatusCd = 1;
    //     localnglishBookChMissionList.stepStatusCd = 1;
    //   } else if (localEnglishBookChallenge.stepStatusCd === 2) {
    //     localEnglishBookChallenge.misStep++;
    //     localEnglishBookChallenge.stepStatusCd = -1;
    //     localnglishBookChMissionList = initEnglishBookChMissionList(localEnglishBookChallenge.misStep);
    //     localEnglishBookChReward = initEnglishBookChReward(localEnglishBookChallenge.misStep);
    //   }
    //   return {
    //     ...state,
    //     localEnglishBookChallenge,
    //     localnglishBookChMissionList,
    //     localEnglishBookChReward,
    //   };
    // },
    // [LOCAL_CHALLENGE_STEP_STATUS_UPDATE]: (state, payload) => {
    //   console.log(payload);
    //   return {
    //     ...state,
    //   };
    // },
    // [HISTORY_BOOKLIST]: (state, payload) => {
    //   let localnglishBookChMissionList = initHistoryEnglishBookChMissionList(payload.misStep);
    //   let localEnglishBookChReward = initEnglishBookChReward(payload.misStep);
    //   return {
    //     ...state,
    //     bookChReward: localEnglishBookChReward,
    //     bookChMissionList: localnglishBookChMissionList,
    //   };
    // },
    // [ENGLISH_BOOK_CHALLENGE_UPDATE] : (state, {payload : bookChallenge}) => ({
    //     ...state,
    //     bookChallenge,
    //     bookChallengeUpdated : true,
    // }),
    // [CLEAR_ENGLISH_BOOK_CHALLENGE_UPDATE] : (state) => ({
    //   ...state,
    //   bookChallengeUpdated : false,
    // }),
    // [CLEAR_ENGLISH_BOOK_CHALLENGE] : (state) => ({
    //   ...state,
    //   bookChallenge : null,
    // }),
  },
  initailState,
);

export default englishBookPick;
