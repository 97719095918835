import API from './API';

export const getHlogList = ({ p, yyyy, page, pageSize }) => {
  //상장 목록 - 학습기용(슬라이드)
  return API.get(`/stud/getHlogList`, {
    params: {
      //query string
      p,
      yyyy,
      page,
      pageSize,
    },
  });
};
//prize
export const getHlogDetailList = ({ p, yyyy, page }) => {
  //상장 목록 - 학습기용(슬라이드)
  return API.get(`/stud/getHlogDetailList`, {
    params: {
      //query string
      p,
      yyyy,
      page,
    },
  });
};

export const getHlogThnList = ({ p, yyyy, page }) => {
  //상장 썸네일 목록
  return API.get(`/stud/getHlogThnList`, {
    params: {
      //query string
      p,
      yyyy,
      page,
    },
  });
};

export const getHlogDetail = ({ p, cd, grpCd }) => {
  //상장 상세 정보
  return API.get(`/stud/getHlogDetail`, {
    params: {
      //query string
      p,
      cd,
      grpCd,
    },
  });
};

export const getHlogCnt = ({ p }) => {
  return API.get(`/stud/getHlogCnt`, {
    params: {
      p,
    },
  });
};
