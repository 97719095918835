import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { getChMetaphorHistory, getChMetaphorObjectStt, getChHabitMissionInfo, getStepUpMissionInfo } from '../api/methods';

//ACTION TYPE
const [CH_METAPHOR_HISTORY, CH_METAPHOR_HISTORY_SUCCESS, CH_METAPHOR_HISTORY_FAILURE] =
  createRequestActionTypes('challenge/CH_METAPHOR_HISTORY');
const [CH_METAPHOR_OBJECT_STT, CH_METAPHOR_OBJECT_STT_SUCCESS, CH_METAPHOR_OBJECT_STT_FAILURE] =
  createRequestActionTypes('challenge/CH_METAPHOR_OBJECT_STT');
const [CH_HABIT_MISSION_INFO, CH_HABIT_MISSION_INFO_SUCCESS, CH_HABIT_MISSION_INFO_FAILURE] =
  createRequestActionTypes('challenge/CH_HABIT_MISSION_INFO');
const [CH_STEP_UP_MISSION_INFO, CH_STEP_UP_MISSION_INFO_SUCCESS, CH_STEP_UP_MISSION_INFO_FAILURE] =
  createRequestActionTypes('challenge/CH_STEP_UP_MISSION_INFO');
const LOAD_QUATER01 = 'challenge/LOAD_QUATER01';
const LOAD_QUATER02 = 'challenge/LOAD_QUATER02';
const LOAD_QUATER03 = 'challenge/LOAD_QUATER03';
const LOAD_QUATER04 = 'challenge/LOAD_QUATER04';
const LOCAL_CH_METAPHOR_OBJECT_STT = 'challenge/LOCAL_CH_METAPHOR_OBJECT_STT';
const LOCAL_CH_HABIT_MISSION_INFO = 'challenge/LOCAL_CH_HABIT_MISSION_INFO';
const LOCAL_CH_STEP_UP_MISSION_INFO = 'challenge/LOCAL_CH_STEP_UP_MISSION_INFO';

//CREATE ACTION
export const chMetaphorHistoryAction = createAction(CH_METAPHOR_HISTORY, ({ p, startYyyyMm, endYyyyMm }) => ({
  p,
  startYyyyMm,
  endYyyyMm,
}));
export const chMetaphorObjectSttAction = createAction(CH_METAPHOR_OBJECT_STT, ({ p, startYyyyMm, endYyyyMm }) => ({
  p,
  startYyyyMm,
  endYyyyMm,
}));
export const chHabitMissionInfoAction = createAction(CH_HABIT_MISSION_INFO, (p) => p);
export const chStepUpMissionInfoAction = createAction(CH_STEP_UP_MISSION_INFO, ({ p, startYyyyMm, endYyyyMm }) => ({
  p,
  startYyyyMm,
  endYyyyMm,
}));
export const loadQuater01Action = createAction(LOAD_QUATER01, (flag) => flag);
export const loadQuater02Action = createAction(LOAD_QUATER02, (flag) => flag);
export const loadQuater03Action = createAction(LOAD_QUATER03, (flag) => flag);
export const loadQuater04Action = createAction(LOAD_QUATER04, (flag) => flag);
export const localchMetaphorObjectSttAction = createAction(
  LOCAL_CH_METAPHOR_OBJECT_STT,
  (metaphorObjectSttState) => metaphorObjectSttState,
);
export const localChHabitMissionInfoAction = createAction(LOCAL_CH_HABIT_MISSION_INFO, (habitMissionInfoState) => habitMissionInfoState);
export const localChStepUpMissionInfo = createAction(LOCAL_CH_STEP_UP_MISSION_INFO, (stepUpMissionInfoState) => stepUpMissionInfoState);
//CREATE ACTION SAGA
const chMetaphorHistorySaga = createRequestSaga(CH_METAPHOR_HISTORY, getChMetaphorHistory);
const chMetaphorObjectSttSaga = createRequestSaga(CH_METAPHOR_OBJECT_STT, getChMetaphorObjectStt);
const chHabitMissionInfoSaga = createRequestSaga(CH_HABIT_MISSION_INFO, getChHabitMissionInfo);
const chStepUpMissionInfoSaga = createRequestSaga(CH_STEP_UP_MISSION_INFO, getStepUpMissionInfo);

//CHALLENGE SAGA
export function* challengeSaga() {
  yield takeLatest(CH_METAPHOR_HISTORY, chMetaphorHistorySaga);
  yield takeLatest(CH_METAPHOR_OBJECT_STT, chMetaphorObjectSttSaga);
  yield takeLatest(CH_HABIT_MISSION_INFO, chHabitMissionInfoSaga);
  yield takeLatest(CH_STEP_UP_MISSION_INFO, chStepUpMissionInfoSaga);
}

//STATE
const initailState = {
  metaphorHistoryState: null,
  metaphorObjectSttState: null,
  habitMissionInfoState: null,
  stepUpMissionInfoState: null,
  loadQuater01: false,
  loadQuater02: false,
  loadQuater03: false,
  loadQuater04: false,
};

//REDUCER
const challenge = handleActions(
  {
    [CH_METAPHOR_HISTORY_SUCCESS]: (state, { payload: data }) => ({
      ...state,
      metaphorHistoryState: data,
    }),
    [CH_METAPHOR_HISTORY_FAILURE]: (state, { payload: data }) => ({
      ...state,
      metaphorHistoryState: null,
    }),
    [CH_METAPHOR_OBJECT_STT_SUCCESS]: (state, { payload: data }) => ({
      ...state,
      metaphorObjectSttState: data,
    }),
    [CH_METAPHOR_OBJECT_STT_FAILURE]: (state, { payload: data }) => ({
      ...state,
      metaphorObjectSttState: null,
    }),
    [CH_HABIT_MISSION_INFO_SUCCESS]: (state, { payload: data }) => ({
      ...state,
      habitMissionInfoState: data,
    }),
    [CH_HABIT_MISSION_INFO_FAILURE]: (state, { payload: data }) => ({
      ...state,
      habitMissionInfoState: null,
    }),
    [CH_STEP_UP_MISSION_INFO_SUCCESS]: (state, { payload: data }) => ({
      ...state,
      stepUpMissionInfoState: data,
    }),
    [CH_STEP_UP_MISSION_INFO_FAILURE]: (state, { payload: data }) => ({
      ...state,
      stepUpMissionInfoState: null,
    }),
    [LOAD_QUATER01]: (state, { payload: data }) => ({
      ...state,
      loadQuater01: data,
    }),
    [LOAD_QUATER02]: (state, { payload: data }) => ({
      ...state,
      loadQuater02: data,
    }),
    [LOAD_QUATER03]: (state, { payload: data }) => ({
      ...state,
      loadQuater03: data,
    }),
    [LOAD_QUATER04]: (state, { payload: data }) => ({
      ...state,
      loadQuater04: data,
    }),
    [LOCAL_CH_METAPHOR_OBJECT_STT]: (state, { payload: data }) => ({
      ...state,
      metaphorObjectSttState: data,
    }),
    [LOCAL_CH_HABIT_MISSION_INFO]: (state, { payload: data }) => ({
      ...state,
      habitMissionInfoState: data,
    }),
    [LOCAL_CH_STEP_UP_MISSION_INFO]: (state, { payload: data }) => ({
      ...state,
      stepUpMissionInfoState: data,
    }),
  },
  initailState,
);

export default challenge;
