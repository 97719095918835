import API from './API';

export const getEnglishBookChallenge = ({ p }) => {
  //영어독서습관 _챌린지_단계_정보
  return API.get(`/stud-chl/getEnglishBookChallenge`, {
    params: {
      //query string
      p,
    },
  });
};

export const getEnglishBookChReward = ({ p, misStep }) => {
  //영어독서습관 _챌린지_단계_정보
  return API.get(`/stud-chl/getEnglishBookChReward`, {
    params: {
      //query string
      p,
      misStep,
    },
  });
};

export const getEnglishBookChMissonList = ({ p, misStep }) => {
  //영어독서습관 _챌린지_단계_정보
  return API.get(`/stud-chl/getEnglishBookChMissonList`, {
    params: {
      //query string
      p,
      misStep,
    },
  });
};

export const setChMissonStatusChange = (params) => {
  //챌린지_미션_상태_변경
  const url = `/extRtLog/setChMissonStatusChange`;
  // const params = {
  //   p,
  //   chCd,
  //   misStep,
  //   stepStatusCd,
  // };
  return API.post(url, params);
};

export const getEnglishBookChInsert = ({ p, misStep, bookrInYn = 'N' }) => {
  return API.get(`/stud-chl/getEnglishBookChInsert`, {
    params: {
      //query string
      p,
      misStep,
      bookrInYn,
    },
  });
};
//getEnglishBookChInsert
