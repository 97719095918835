import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { ALERT_POPUP_TYPE, exitPage } from '../lib/createPopup';
import { studInfoFromP, studInfoFromToken, setAuthError } from '../modules/auth';
import { openAlertPopup } from '../modules/popup';
import { Outlet } from 'react-router-dom';
import getCookie from '../common/cookie';

const ProtectedRoute = ({}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [availableChallenge, setAvailableChallenge] = useState(false);
  const [notUsePath, setNotUsePath] = useState(false);
  const { auth, authError } = useSelector(({ auth }) => ({
    auth: auth.auth,
    authError: auth.authError,
  }));

  useEffect(() => {
    console.log('[ProtectedRoute] MOUNTED');
    const p = searchParams.get('p');
    const token = getCookie('token');
    if (!auth) {
      if (p) {
        console.log('[ProtectedRoute] set P Value');
        dispatch(studInfoFromP({ p }));
      } else if (token) {
        console.log('[ProtectedRoute] set Token Value');
        dispatch(studInfoFromToken({ token }));
      } else {
        console.log('[ProtectedRoute] required P || Token');
        dispatch(setAuthError());
      }
    }
  }, [dispatch, searchParams, auth]);

  // useEffect(() => {
  //   // console.log("[ProtectedRoute] location path check!")
  //   // // //PRODUCTION
  //   // if(process.env.REACT_APP_ENV_MODE === "PRODUCTION" &&
  //   //   location.pathname === "/bookpick") {
  //   //   console.log("[ProtectedRoute] location path not use!!!");
  //   //   setNotUsePath(true);
  //   // }
  // }, [location.pathname]);

  useEffect(() => {
    console.log('[ProtectedRoute] Authorization Check');
    if (notUsePath) {
      console.log('[ProtectedRoute] current path not use!');
      const popup = {
        type: ALERT_POPUP_TYPE,
        message: '준비 중이에요.\n잠시 후에 만나요.',
        isOpen: true,
        onClickYes: () => {
          exitPage();
        },
        btnYesText: '확인',
      };
      dispatch(openAlertPopup(popup));
      setAvailableChallenge(false);
    } else if (auth) {
      console.log('[ProtectedRoute] Auth Check');
      console.log(location.pathname);
      if (auth.pkgType === 'ES') {
        //초등
        console.log('[ProtectedRoute] Package Check SUCCESS : ', auth.pkgType);
        setAvailableChallenge(true);
      } else if (auth.pkgType === 'MS') {
        //homeLogYn
        if (auth.msPkgYn === 'Y' && auth.homeLogYn === 'Y' && location.pathname === '/homelog') {
          //중등 전환 & 홈로그
          console.log('[ProtectedRoute] Package Check SUCCESS : ', auth.pkgType, ' msPkgYn : ', auth.msPkgYn);
          setAvailableChallenge(true);
        } else {
          //중등
          const popup = {
            type: ALERT_POPUP_TYPE,
            message: '홈런 초등 회원 전용입니다.',
            isOpen: true,
            onClickYes: () => {
              exitPage();
            },
            btnYesText: '확인',
          };
          console.log('[ProtectedRoute] Package Check FAILURE');
          console.log('중등 패키지 사용 중 입니다.');
          dispatch(openAlertPopup(popup));
          setAvailableChallenge(false);
        }
      } else {
        console.log('[ProtectedRoute] Package Check FAILURE');
        console.log('잘못 된 패키지 정보입니다. : ', auth.pkgType);
        setAvailableChallenge(false);
      }
    } else {
      console.log('[ProtectedRoute] Auth No Data');
      setAvailableChallenge(false);
    }
  }, [auth, dispatch, notUsePath, location]);

  return <>{authError ? <Navigate to="/error" /> : auth && availableChallenge && <Outlet />}</>;
};

export default ProtectedRoute;
