import { BrowserRouter } from 'react-router-dom';
import { addOnBackPressed, defaultBackPressFlag } from './common/GlobalWindowEvent';
import PopupConatiner from './components/Popup/PopupContainer';
import { exitPage } from './lib/createPopup';
import AppRouter from './router/AppRouter';
const getExitMsg = (path) => {
  let msg = '';
  switch (path) {
    case '/challenge':
      msg = '내 나무 숲 가꾸기를 종료\n하시겠습니까?';
      break;
    case '/bookpick/eng':
      msg = '도서픽을 종료하시겠습니까?';
      break;
    case '/bookpick':
      msg = '도서픽을 종료하시겠습니까?';
      break;
    default:
      msg = '내 나무 숲 가꾸기를 종료\n하시겠습니까?';
  }
  return msg;
};
const App = () => {
  console.log(process.env.REACT_APP_ENV_MODE);
  if (window.Android) {
    window.addEventListener('popstate', (e) => {
      e.preventDefault();
      console.log('popstate occur!');
    });
    addOnBackPressed();
    const onBackPress = () => {
      console.log('default BackPress Event : ', defaultBackPressFlag);
      if (window.location.pathname !== '/homelevel') {
        if (defaultBackPressFlag) {
          const EXIT_MSG = getExitMsg(window.location.pathname);
          window.AlertfuncOK = () => {
            exitPage();
          };
          window.Android.alert(EXIT_MSG, 'AlertfuncOK()', '예');
        }
      }
    };
    window.addEventListener('backpress', onBackPress);
  }

  return (
    <>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <PopupConatiner />
    </>
  );
};

export default App;
// 설치 모듈 리스트
// axios, jquery, swiper, lottie-web
