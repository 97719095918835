const ConfirmPopup = ({ onYesHandler, onNoHandler, btnYesText, btnNoText, message, isOpen }) => {
  const msg = message.replace('\n', '<br/>');
  return (
    <div className={`share-pop-ui ${isOpen ? 'open' : ''}`}>
      <span className="dim"></span>
      <div className="dp-table  pos-a">
        <div className="dp-cell">
          <div className="share-comfirm-box">
            <div className="share-txt-area">
              <div className="share-txt">
                <div className="dp-table">
                  <div className="dp-cell">
                    <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="share-btn-area">
              <ul>
                <li className="share-btn btn-o">
                  <button className="clickable" onClick={onYesHandler}>
                    {btnYesText}
                  </button>
                </li>
                <li className="share-btn btn-x">
                  <button className="clickable" onClick={onNoHandler}>
                    {btnNoText}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
