export const addOnResume = () => {
  console.log('add window OnResume');
  window.onResume = () => {
    console.log('occur window OnResume');
    const event = new Event('onResume');
    window.dispatchEvent(event);
  };
};

export const deleteOnResume = () => {
  console.log('delete window onResume');
  window.onResume = null;
};

export const addOnPause = () => {
  console.log('add window OnPause');
  window.onPause = () => {
    console.log('occur window OnPause');
    const event = new Event('onPause');
    window.dispatchEvent(event);
  };
};

export const deleteOnPause = () => {
  console.log('delete window OnPause');
  window.onPause = null;
};

export const addOnBackPressed = () => {
  console.log('add window onBackPressed');
  window.onBackPressed = () => {
    console.log('occur window onBackPressed');
    const event = new Event('backpress');
    window.dispatchEvent(event);
  };
};

export const deleteOnBackPressed = () => {
  console.log('delete window OnBackPressed');
  window.onBackPressed = null;
};

export const addWindowEvent = (type) => {
  switch (type) {
    case 'onResume':
      addOnResume();
      break;
    case 'onPause':
      addOnPause();
      break;
    case 'onBackpressed':
      addOnBackPressed();
      break;
    default:
      console.log(`This Event ${type}type is not supported`);
      break;
  }
};

export const deleteWindowEvent = (type) => {
  switch (type) {
    case 'onResume':
      deleteOnResume();
      break;
    case 'onPause':
      deleteOnPause();
      break;
    case 'onBackpressed':
      deleteOnBackPressed();
      break;
    default:
      console.log(`This Event ${type}type is not supported`);
      break;
  }
};

const preventGoBack = () => {
  console.log('preventGoBack');
  window.history.go(1);
};

export const initPopState = () => {
  if (window.Android) {
    console.log('add EventListener popstate');
    window.history.pushState(null, null, window.location.href);
    console.log('pushState', window.location.href);
    window.addEventListener('popstate', preventGoBack);
  }
};

export const clearPopState = () => {
  if (window.Android) {
    console.log('delete EventListener popstate');
    window.removeEventListener('popstate', preventGoBack);
  }
};

export var defaultBackPressFlag = false;

export const enableDefaultBackPress = () => {
  console.log('enableDefaultBackPress');
  defaultBackPressFlag = true;
};

export const disableDefaultBackPress = () => {
  console.log('disableDefaultBackPress');
  defaultBackPressFlag = false;
};
