import { createAction, handleActions } from 'redux-actions';

const OPEN_CONFIRM_POPUP = 'popup/OPEN_CONFIRM_POPUP';
const OPEN_ALERT_POPUP = 'popup/OPEN_ALERT_POPUP';
const CLEAR_POPUP = 'popup/CLEAR_POPUP';

export const openConfirmPopup = createAction(OPEN_CONFIRM_POPUP, (popup) => popup);

export const openAlertPopup = createAction(OPEN_ALERT_POPUP, (popup) => popup);

export const clearPopup = createAction(CLEAR_POPUP);

const initailState = {
  type: '', //confirm || alert
  message: '', //
  isOpen: false,
  onClickYes: '',
  onClickNo: '',
  btnYesText: '확인',
  btnNoText: '취소',
};

const popup = handleActions(
  {
    [OPEN_CONFIRM_POPUP]: (state, { payload: { type, message, isOpen, onClickYes, onClickNo, btnYesText, btnNoText } }) => ({
      ...state,
      type,
      message,
      isOpen,
      onClickYes,
      onClickNo,
      btnYesText,
      btnNoText,
    }),
    [OPEN_ALERT_POPUP]: (state, { payload: { type, message, isOpen, onClickYes, btnYesText } }) => ({
      ...state,
      type,
      message,
      isOpen,
      onClickYes,
      btnYesText,
    }),
    [CLEAR_POPUP]: (state) => ({
      ...state,
      type: '',
      message: '',
      isOpen: false,
      onClickYes: '',
      onClickNo: '',
      btnYesText: '확인',
      btnNoText: '취소',
    }),
  },
  initailState,
);

export default popup;
