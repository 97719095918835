import { combineReducers } from 'redux';

import bookpick, { bookPickSaga } from './bookpick';
import challenge, { challengeSaga } from './challenge';
import homeLog, { homeLogSaga } from './homeLog';
import auth, { authSaga } from './auth';
import englishBookPick, { englishBookPickSaga } from './englishBookPick';

import loading from './loading';
import popup from './popup';
import { all } from 'redux-saga/effects';

const rootReducer = combineReducers({ bookpick, challenge, auth, loading, popup, homeLog, englishBookPick });

export function* rootSaga() {
  yield all([bookPickSaga(), challengeSaga(), authSaga(), homeLogSaga(), englishBookPickSaga()]);
}

export default rootReducer;
