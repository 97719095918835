import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { getKoreanBookChallenge, getKoreanBookChReward, getKoreanBookChMissonList, setChMissonStatusChange } from '../api/methods';

//ACTION TYPE
const [KOREAN_BOOK_CHALLENGE, KOREAN_BOOK_CHALLENGE_SUCCESS, KOREAN_BOOK_CHALLENGE_FAILURE] =
  createRequestActionTypes('bookpick/KOREAN_BOOK_CHALLENGE');
const [KOREAN_BOOK_CH_REWARD, KOREAN_BOOK_CH_REWARD_SUCCESS, KOREAN_BOOK_CH_REWARD_FAILURE] =
  createRequestActionTypes('bookpick/KOREAN_BOOK_CH_REWARD');
const [KOREAN_BOOK_CH_MISSION_LIST, KOREAN_BOOK_CH_MISSION_LIST_SUCCESS, KOREAN_BOOK_CH_MISSION_LIST_FAILURE] = createRequestActionTypes(
  'bookpick/KOREAN_BOOK_CH_MISSION_LIST',
);
const SET_KOREAN_BOOK_CHALLENGE = 'bookpick/SET_KOREAN_BOOK_CHALLENGE';
const KOREAN_BOOK_CHALLENGE_UPDATE = 'bookpick/KOREAN_BOOK_CHALLENGE_UPDATE';
const CLEAR_KOREAN_BOOK_CHALLENGE_UPDATE = 'bookpick/CLEAR_KOREAN_BOOK_CHALLENGE_UPDATE';
const CLEAR_KOREAN_BOOK_CHALLENGE = 'bookpick/CLEAR_KOREAN_BOOK_CHALLENGE';
//CREATE ACTION
export const koreanBookChallenge = createAction(KOREAN_BOOK_CHALLENGE, (p) => p);
export const koreanBookChReward = createAction(KOREAN_BOOK_CH_REWARD, ({ p, misStep }) => ({ p, misStep }));
export const koreanBookChMissionList = createAction(KOREAN_BOOK_CH_MISSION_LIST, ({ p, misStep }) => ({ p, misStep }));
export const setKoreanBookChallenge = createAction(SET_KOREAN_BOOK_CHALLENGE, ({ p, chCd, misStep, misStatusCd }) => ({
  p,
  chCd,
  misStep,
  misStatusCd,
}));
export const koreanBookChallengeUpdate = createAction(KOREAN_BOOK_CHALLENGE_UPDATE, (bookChallenge) => bookChallenge);
export const clearKoreanBookChallengeUpdate = createAction(CLEAR_KOREAN_BOOK_CHALLENGE_UPDATE);
export const clearKoreanBookChallenge = createAction(CLEAR_KOREAN_BOOK_CHALLENGE);
//CREATE ACTION SAGA
const koreanBookChallengeSaga = createRequestSaga(KOREAN_BOOK_CHALLENGE, getKoreanBookChallenge);
const koreanBookChRewardSaga = createRequestSaga(KOREAN_BOOK_CH_REWARD, getKoreanBookChReward);
const koreanBookChMissionListSaga = createRequestSaga(KOREAN_BOOK_CH_MISSION_LIST, getKoreanBookChMissonList);

//BOOKPICK SAGA
export function* bookPickSaga() {
  yield takeLatest(KOREAN_BOOK_CHALLENGE, koreanBookChallengeSaga);
  yield takeLatest(KOREAN_BOOK_CH_REWARD, koreanBookChRewardSaga);
  yield takeLatest(KOREAN_BOOK_CH_MISSION_LIST, koreanBookChMissionListSaga);
}

//STATE
const initailState = {
  bookChallenge: null,
  bookChallengeError: null,
  bookChReward: null,
  bookChMissionList: null,
  bookChallengeUpdated: null,
};

//REDUCER
const bookpick = handleActions(
  {
    [KOREAN_BOOK_CHALLENGE_SUCCESS]: (state, { payload: bookChallenge }) => ({
      ...state,
      bookChallenge,
      bookChallengeError: null,
      // bookChallenge : null,
      // bookChallengeError : true
    }),
    [KOREAN_BOOK_CHALLENGE_FAILURE]: (state, { payload: bookChallenge }) => ({
      ...state,
      bookChallenge: null,
      bookChallengeError: true,
    }),
    [KOREAN_BOOK_CH_REWARD_SUCCESS]: (state, { payload: bookChReward }) => ({
      ...state,
      bookChReward,
    }),
    [KOREAN_BOOK_CH_REWARD_FAILURE]: (state, { payload: bookChReward }) => ({
      ...state,
      bookChReward: null,
    }),
    [KOREAN_BOOK_CH_MISSION_LIST_SUCCESS]: (state, { payload: bookChMissionList }) => ({
      ...state,
      bookChMissionList,
    }),
    [KOREAN_BOOK_CH_MISSION_LIST_FAILURE]: (state, { payload: bookChMissionList }) => ({
      ...state,
      bookChMissionList: null,
    }),
    [KOREAN_BOOK_CHALLENGE_UPDATE]: (state, { payload: bookChallenge }) => ({
      ...state,
      bookChallenge,
      bookChallengeUpdated: true,
    }),
    [CLEAR_KOREAN_BOOK_CHALLENGE_UPDATE]: (state) => ({
      ...state,
      bookChallengeUpdated: false,
    }),
    [CLEAR_KOREAN_BOOK_CHALLENGE]: (state) => ({
      ...state,
      bookChallenge: null,
    }),
  },
  initailState,
);

export default bookpick;
