import { useRoutes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { lazy, Suspense } from 'react';

const BookPick = lazy(() => import('../views/BookPickView'));
const HomeLevel = lazy(() => import('../components/HomeLevel/HomeLevel'));
const Challenge = lazy(() => import('../views/ChallengeView'));
const EnglishBookPick = lazy(() => import('../views/EnglishBookPickView'));
const HomeLog = lazy(() => import('../views/HomeLogView'));
const Error = lazy(() => import('../components/Error/Error'));

function AppRouter() {
  const routes = useRoutes([
    {
      element: <ProtectedRoute />,
      children: [
        { path: '/homelevel', element: <HomeLevel /> },
        { path: '/challenge', element: <Challenge /> },
        { path: '/homelog', element: <HomeLog /> },
        {
          path: '/bookpick',
          children: [
            { index: true, element: <BookPick /> },
            // { path: 'eng', element: <EnglishBookPick /> },
            { path: '*', element: <Error /> },
          ],
        },
        // {
        //   path: '/englishbookpick',
        //   element: <EnglishBookPick />,
        // },
      ],
    },
    { path: '/*', element: <Error /> },
  ]);

  return <Suspense fallback={<div></div>}>{routes}</Suspense>;
}

export default AppRouter;
