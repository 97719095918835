const postMessage = (data) => {
  if (window.parent) {
    window.parent.postMessage(data, '*');
  }
};

export const backGroundModeOn = () => {
  postMessage({ backGroundMode: 'on' });
};
export const backGroundModeOff = () => {
  postMessage({ backGroundMode: 'off' });
};
export const backGroundModeFadeInOut = () => {
  postMessage({ backGroundMode: 'fadeInOut' });
};
export const backGroundModeDefault = () => {
  postMessage({ backGroundMode: 'default' });
};

export const homeLogAnimation = () => {
  postMessage({ homeLogAnimation: true });
};

export const setLoading = (type) => {
  postMessage({ loading: type });
};

export default postMessage;
