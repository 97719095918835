import { ALERT_POPUP_TYPE, exitPage } from '../lib/createPopup';
import { openAlertPopup } from '../modules/popup';
import API from './API';

const api_get = async (url, params, setter) => {
  const response = await API.get(url, { params: params });
  response
    .then((res) => {
      if (res.data.msg.resultCode === '0000') {
        // console.log(res.data.data);
        setter(res.data.data);
      } else {
      }
    })
    .catch((error) => {});
};

const sync_api_get = async (url, params) => {
  try {
    const response = await API.get(url, { params: params });
    // console.log(response.data.data)
    if (response.status === 200 && response.data.msg.resultCode === '0000') {
      return {
        result: 'success',
        data: response.data.data,
      };
    } else {
      let message = '';
      switch (response.data.msg.resultCode) {
        case '1100':
          message = '필수값 누락';
          break;
        case '1200':
          message = 'Length 오류';
          break;
        case '1310':
          message = 'DataType 불일치 (Integer)';
          break;
        case '1320':
          message = '월간/주간 구분 파라미터 오류';
          break;
        case '1330':
          message = 'DataType 불일치 (Date)';
          break;
        case '1340':
          message = '숫자범위 오류';
          break;
        case '1350':
          message = '과목명 오류';
          break;
        case '2000':
          message = '데이터 없음';
          break;
        case '9000':
          message = '외부API 호출오류';
          break;
        case '9999':
          message = '시스템 오류, 기타 오류';
          break;
        default: {
          message = '알 수 없는 오류입니다. [' + response.data.msg.resultCode + ']';
        }
      }

      console.log('url : ', url);
      console.log('error : ', message);

      const popup = {
        type: ALERT_POPUP_TYPE,
        message: '인터넷 연결에 실패했습니다.',
        isOpen: true,
        onClickYes: () => {
          exitPage();
        },
        btnYesText: '확인',
      };
      openAlertPopup(popup);
      return {
        resultCode: response.data.msg.resultCode,
        result: 'fail',
        message: message,
      };
    }
  } catch (error) {
    console.log('url : ', url);
    console.log('error : ', error);

    // if (error.code === "ECONNABORTED" || error.code === "ERR_NETWORK" || error.response?.status === 408) {
    //     // alert("인터넷 연결에 실패했습니다.");
    //     return {
    //         result : "fail",
    //         message : "인터넷 연결에 실패했습니다."
    //     };
    // }
    // else {
    //     return {
    //         result : "fail",
    //         message : error.message
    //     };
    // }
    const popup = {
      type: ALERT_POPUP_TYPE,
      message: '인터넷 연결에 실패했습니다.',
      isOpen: true,
      onClickYes: () => {
        exitPage();
      },
      btnYesText: '확인',
    };
    openAlertPopup(popup);
    return {
      result: 'fail',
      message: error.message,
    };
  }
};

// homeload
export const getLrnTypeCheck = (params) => {
  // STUD-LT-001 학습유형 확인
  return sync_api_get('/stud/getLrnTypeCheck', params);
};

export const getLrnTypeDetail = (params) => {
  // STUD-LT-002 학습분석 상세
  return sync_api_get('/stud/getLrnTypeDetail', params);
};

export const getLrnTypeHistory = (params) => {
  // STUD-LT-003 학습유형 내역
  return sync_api_get('/stud/getLrnTypeHistory', params);
};

export const getLrnTypePathInfo = (params) => {
  // STUD-LT-004 학생 학습유형 경로 정보
  return sync_api_get('/stud/getLrnTypePathInfo', params);
};

//common
export const getStudInfo = ({ token }) => {
  const url = `/stud/getStudInfo`;
  return API.post(url, { token });
};

export const getStudInfoFromP = ({ p }) => {
  const url = `/stud/getStudInfo?p=${p}`;
  return API.get(url);
};

//bookpick
export const getKoreanBookChallenge = (p) => {
  //국어책_챌린지_정보
  const url = `/stud-chl/getKoreanBookChallenge?p=${p}`;
  return API.get(url);
};

export const getKoreanBookChReward = ({ p, misStep }) => {
  //국어책_챌린지_보상_정보
  const url = `/stud-chl/getKoreanBookChReward?p=${p}&misStep=${misStep}`;
  return API.get(url);
};

export const getKoreanBookChMissonList = ({ p, misStep }) => {
  //국어책_챌린지_미션_리스트_정보
  const url = `/stud-chl/getKoreanBookChMissonList?p=${p}&misStep=${misStep}`;
  return API.get(url);
};

export const setChMissonStatusChange = ({ p, chCd, misStep, stepStatusCd }) => {
  //챌린지_미션_상태_변경
  const url = `/extRtLog/setChMissonStatusChange`;
  const params = {
    p,
    chCd,
    misStep,
    stepStatusCd,
  };
  return API.post(url, params);
};

export const setChKoreanBookMisNo = ({ p, misStep, misNo, misStatusCd }) => {
  const params = {
    p,
    misStep,
    misNo,
    misStatusCd,
  };
  return API.post(`/stud-chl/setChKoreanBookMisNo`, params);
};

//Challenge
export const getChMetaphorHistory = ({ p, startYyyyMm, endYyyyMm }) => {
  const pCheck = p;
  const url = `/stud-chl/getChMetaphorHistory?p=${pCheck}&startYyyyMm=${startYyyyMm}&endYyyyMm=${endYyyyMm}`;
  return API.get(url);
  // return API.get('stud-chl/getChMetaphorHistory', {p, startYyyyMm, endYyyyMm});
};

export const getChMetaphorObjectStt = ({ p, startYyyyMm, endYyyyMm }) => {
  // 성장메타포 오브젝트 현황
  const pCheck = p;
  const url = `stud-chl/getChMetaphorObjectStt?p=${pCheck}&startYyyyMm=${startYyyyMm}&endYyyyMm=${endYyyyMm}`;
  return API.get(url);
  // return sync_api_get('stud-chl/getChMetaphorObjectStt', {p, startYyyyMm, endYyyyMm});
};

export const getChHabitMissionInfo = (p) => {
  // 성장메타포 오브젝트 현황
  const pCheck = p;
  const url = `stud-chl/getChHabitMissionInfo?p=${pCheck}`;
  return API.get(url);
  // return sync_api_get('stud-chl/getChHabitMissionInfo', {p});
};

export const getStepUpMissionInfo = ({ p, startYyyyMm, endYyyyMm }) => {
  // 오늘의 미션정보 : 한단계 UP 챌린지
  const pCheck = p;
  const url = `stud-chl/getChStepUpMissionInfo?p=${pCheck}&startYyyyMm=${startYyyyMm}&endYyyyMm=${endYyyyMm}`;
  return API.get(url);
  // return sync_api_get('stud-chl/getStepUpMissionInfo', {p, startYyyyMm, endYyyyMm});
};

export const setRegFnWaterJug = ({ p, misStep }) => {
  //오늘의 미션 정보 : 매일 홈런하는 습관 물주기 완료 등록
  const pCheck = p;
  const url = `/extRtLog/regFnWaterJug`;
  const params = {
    p: pCheck,
    misStep,
  };
  return API.post(url, params);
};

export const setRegFnObjectReward = ({ p, rewardStep }) => {
  //메타포 오브젝트 현황 : 한 단계 UP 챌린지 보상 완료 등록
  const pCheck = p;
  const url = `/extRtLog/regFnObjectReward`;
  const params = {
    p: pCheck,
    rewardStep,
  };
  return API.post(url, params);
};

export const setRegFnParticle = ({ p, misStep }) => {
  //오늘의 미션 정보 : 매일 홈런하는 습관 물주기 완료 등록
  const pCheck = p;
  const url = `/extRtLog/regFnParticle`;
  const params = {
    p: pCheck,
    misStep,
  };
  return API.post(url, params);
};

export const setRegFnObjectRewardMmc = ({ p, yyyyMm }) => {
  //메타포 오브젝트 현황 : 한 단계 UP 챌린지 보상 완료 등록
  const pCheck = p;
  const url = `/extRtLog/regFnObjectRewardMmc`;
  const params = {
    p: pCheck,
    yyyyMm,
  };
  return API.post(url, params);
};

export const regFnObjectRewardMeb = ({ p, rewardStep }) => {
  const params = {
    p,
    rewardStep,
  };
  return API.post(`/extRtLog/regFnObjectRewardMeb`, params);
};
